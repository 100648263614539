<template>
  <div class="app-container">
    <div class="app_con">
      <app-list
        ref="list"
        :remote="remote"
        :props="props"
        :formProps="formProps"
        :searchQuery="searchQuery"
        :apiName="apiName"
        :rules="rules"
        :editTitle="'每日菜价'"
      >
        <div class="search" slot="tool">
          <div class="searchInput el-input-group">
            <div class="el-input-group__prepend">日期</div>
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="searchQuery.date"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
          <el-upload
            :headers="headers"
            action="/api/market/dailyPrice/importExcel"
            :show-file-list="false"
            :on-success="onSuccess"
            :on-error="onError"
            style="display: inline-flex; margin-right: 10px"
          >
            <el-button icon="el-icon-upload" accept=".xls,.xlsx"
              >上传每日菜价</el-button
            >
          </el-upload>
          <el-button @click="onDownloadTemplate" style="margin-bottom: 10px"
            >下载模板</el-button
          >
        </div>
      </app-list>
    </div>
  </div>
</template>

<script>
import api from "@/api/common";

export default {
  data() {
    return {
      remote: api,
      apiName: "/market/dailyPrice",
      commodityApiName: "/market/commodity/listAll",
      searchQuery: { date: "" },
      labelWidth: "120px",
      rules: {
        //传递给applist的表单验证
        date: [{ required: true, message: "请选择日期", trigger: "blur" }],
        marketCommodityId: [
          { required: true, message: "请选择商品", trigger: "blur" },
        ],
        unitPrice: [
          {
            required: true,
            validator: (relu, value, callback) => {
              if (!value) {
                callback(new Error("请输入价格"));
              } else if (!/^\d+$/.test(value)) {
                callback(new Error("请填写阿拉伯数"));
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"],
          },
        ],
      },
      props: [
        {
          label: "日期",
          prop: "dishDate",
          align: "center",
        },
        {
          label: "商品",
          prop: "name",
          align: "center",
        },
        {
          label: "价格",
          prop: "unitPrice",
          align: "center",
        },
      ],
      formProps: [
        {
          label: "日期",
          prop: "date",
          type: "date",
        },
        {
          label: "商品",
          prop: "marketCommodityId",
          type: "select",
          selectData: [],
        },
        {
          label: "价格",
          prop: "unitPrice",
          type: "input",
        },
      ],
    };
  },
  created() {
    this.getCommondity();
  },
  methods: {
    async getCommondity() {
      console.log(api);
      await api.list({ apiName: this.commodityApiName }).then((res) => {
        console.log("vsdfsd-->", res);
        this.formProps[1].selectData = res.data.map((x) => {
          return {
            label: x.name,
            value: x.id,
          };
        });
        // this.data = res.data.data;
        // this.pageInfo.total = parseInt(res.data.total);
      });
    },
    async onDownloadTemplate() {
      await api.download({
        apiName: "/market/dailyPrice/exportExcelTemplate",
      });
    },
    onSuccess() {
      this.$refs.list.getItems();
    },
    onError(err) {
      const res = JSON.parse(err.message);
      this.$message({
        message: res.message,
        type: "error",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-date-editor {
  height: 40px;
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
